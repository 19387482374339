<template>
    <div class="outer-wrapper">
        <div class="me-wrapper">
            <div class="right-wrapper">
                <div class="right-side-inner-wrapper">
                    <div class="r-s-top">
                        <span>同线路其它风险点
                            <Icon type="ios-arrow-down" /></span>
                    </div>
                    <div v-for="ele in content_other" class="right-risk-element" :key="ele.id">
                            <router-link :to="{name:'risk_c',params:{risk_id:ele.id.toString()}}">
                        <div class="element-label">{{ele.degree_level}}</div>
                        <div class="element-wrapper">
                            <div>
                                <p class='p-p'>
                                    {{ele.risk_content}}
                                </p>
                            </div>
                            <Divider dashed />
                            <Tag color="blue">{{ele.sort}}</Tag>
                            <Tag color="cyan">{{ele.major_system}}</Tag>
                            <Tag color="lime">{{ele.correct_demand}}</Tag>
                        </div>
                    </router-link>
                    </div>
                </div>
            </div>
            <div class="left-content">
                <div class="one-top-wrapper">
                    <div class="line-label">
                        <Icon type="md-subway" /><span class="city-title-tag">{{content.city}}</span><span class='line-title-tag'>{{content.line_name}}</span>
                    </div>
                    <div class="demand-label">
                        <span>{{content.risk_correct_stat}}</span>
                    </div>
                    <div class="one-top">
                        <!-- <div class="lr-wrapper">
                            <i-circle :percent="100" stroke-color="#006699">
                                <span class="demo-Circle-inner" style="font-size:24px">{{content.score}}分</span>
                            </i-circle>
                        </div> -->
                        <div class="ll-wrapper">
                            <div class="tll-title">
                                <p>{{content.risk_content}}</p>
                                <span class="year-title">——来自于{{content.risk_year}}运营安全评价</span>
                            </div>
                            <div class="tll-description">
                                <div class="tll-d-title">
                                <p class="p-p descri">{{content.description}}
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="two-top">
                        <div class="tt-inner-wrapper">
                            <table class="prop-t">
                                <tr>
                                    <td class="title-td">类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</td>
                                    <td class="content-td">{{content.sort}}</td>
                                    <td class="title-td">严重等级：</td>
                                    <td class="content-td">{{content.degree_level}}</td>
                                </tr>
                                <tr>
                                    <td class="title-td">相关技术：</td>
                                    <td class="content-td">{{content.major_system}}</td>
                                    <td class="title-td">评价时间：</td>
                                    <td class="content-td">{{content.evaluate_year}}</td>
                                </tr>
                                <tr>
                                    <td class="title-td">整改要求：</td>
                                    <td class="content-td">{{content.correct_demand}}</td>
                                    <td class="title-td"></td>
                                    <td class="content-td"></td>
                                </tr>
                            </table>
                            <p class="instr-p">*A：设计标准差异；B：设备老化伤损严重可靠性差；C：管理问题；D：专业接口；E；其他。</p>
                        </div>
                    </div>
                    <div class="two-top">
                        <Divider><span class="demand-tt">专家建议</span></Divider>
                        <div class="demand-top">

                            <p class='p-p'>
                                {{content.solution_proposal}}
                            </p>
                        </div>
                        <template v-if="content.correct_operation">
                        <Divider><span class="demand-tt">整改措施</span></Divider>
                        <div class="demand-med">
                            <p class='p-p'>
                                {{content.correct_operation}}
                            </p>
                        </div>
                        <!-- <Divider><span class="demand-tt">进展情况</span></Divider>
                        <div class="demand-med">
                            <p class='p-p'>
                                {{content.recent_stat_description}}
                            </p> -->
                            <!--<Timeline>
                                <TimelineItem>
                                    <p class="time">2018年6月15日</p>
                                    <p class="content">发现风险点</p>
                                </TimelineItem>
                                <TimelineItem>
                                    <p class="time">2018年11月22日</p>
                                    <Poptip word-wrap trigger="hover" placement="right">
                                        <p class="content">对该风险点进行第一次整改</p>

                                        <div class="api" slot="content">
                                            <p>对隧道内径变化较大区段，缩短检测周期，加强监控；对内径变化超限地段采取整治措施。</p>
                                        </div>

                                    </Poptip>
                                </TimelineItem>
                                <TimelineItem>
                                    <p class="time">2018年12月10日</p>
                                    <Poptip word-wrap trigger="hover" placement="right">
                                        <p class="content">针对第一次整改情况进行评审并得到评审结果</p>
                                        <p class="content">仍需继续整改</p>
                                        <div class="api" slot="content">
                                            <p>对隧道内径变化较大区段，缩短检测周期，加强监控；对内径变化超限地段采取整治措施。</p>
                                        </div>
                                    </Poptip>
                                </TimelineItem>
                                <TimelineItem>
                                    <p class="time">2019年1月20日</p>
                                    <Poptip word-wrap trigger="hover" placement="right">
                                        <p class="content">对该风险点进行第二次整改</p>
                                        <div class="api" slot="content">
                                            <p>对隧道内径变化较大区段，缩短检测周期，加强监控；对内径变化超限地段采取整治措施。</p>
                                        </div>
                                    </Poptip>
                                </TimelineItem>
                                <TimelineItem>
                                    <p class="time">2019年2月5日</p>
                                    <Poptip word-wrap trigger="hover" placement="right">
                                        <p class="content">针对第二次整改情况进行评审合格</p>
                                        <div class="api" slot="content">
                                            <p>对隧道内径变化较大区段，缩短检测周期，加强监控；对内径变化超限地段采取整治措施。</p>
                                        </div>
                                    </Poptip>
                                </TimelineItem>
                                <TimelineItem>
                                    <p class="time">2019年2月5日</p>
                                    <Poptip word-wrap trigger="hover" placement="right">
                                        <p class="content">该风险已解除并关闭</p>
                                        <div class="api" slot="content">
                                            <p>对隧道内径变化较大区段，缩短检测周期，加强监控；对内径变化超限地段采取整治措施。</p>
                                        </div>
                                    </Poptip>
                                </TimelineItem>
                            </Timeline>-->
                        <!-- </div> -->
                        <Divider><span class="demand-tt">整改确认</span></Divider>
                        <div class="demand-med">
                            <p class='p-p'>
                                <span v-if="content.confirm_stat">【{{content.confirm_stat}}】</span>
                                <span v-else>【未确认】</span>
                                {{content.confirm_remark}}
                            </p>
                        </div>
                    </template>
                    <template v-else>
                            <Divider><span class="demand-tt">整改详情</span></Divider>
                            <div class="demand-med">
                                <p class='p-p'>
                                    暂无
                                </p>
                            </div>
                    </template>
                        <div></div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    let userToken = localStorage.getItem("current_user_token");
    export default {
        name: 'riskContent',
        components: {},
        data() {
            return {
                content: {},
                content_other: []
            }
        },
        props: {
            risk_id: {
                type: String,
                default: "1"
            }

        },
        created() {
            this.get_data();
            this.get_other_data();
        },
        beforeRouteUpdate(to, from, next) {
            this.get_data(to.params.risk_id);
            this.get_other_data(to.params.risk_id);
            next();
        },
        methods: {
            get_data(id=null) {
                if(id===null){
                    id = this.risk_id;
                }
                this.$axios.post(this.$url + '/risk_content', {
                    risk_id: id,userToken:userToken
                }).then(response => (this.content = response.data));
            },
            get_other_data(id=null) {
                if(id===null){
                    id = this.risk_id;
                }
                this.$axios.post(this.$url + '/risk_content_other', {
                    risk_id: id,userToken:userToken
                }).then(response => (this.content_other = response.data));
            }
        }


    }
</script>
<style scoped>

    .me-wrapper {
        overflow: hidden;
    }

    .left-content {
        margin-right: 300px;
    }

    .one-top-wrapper {
        position: relative;
        padding: 20px;
        margin-bottom: 30px;
    }

    .right-wrapper {
        width: 350px;
        float: right;
        padding: 20px;
    }

    .right-side-inner-wrapper {
        box-shadow: 2px 2px 1px #aaa;
        border: 1px solid #eee;
        overflow: hidden;
    }

    .r-s-top {
        text-align: center;
        padding: 10px;
        border-bottom: 1px dotted #eee;
        font-size: 14px;
        color: #aaa;
        font-weight: 700;
    }

    .line-label {
        position: absolute;
        top: 2px;
        left: 60px;
        background: #fff;
        color: #333;
        font-size: 24px;
        font-weight: 700;
        padding: 0 20px;

    }

    .city-title-tag {
        font-family: 'LiSu';
        margin: 0 10px;

    }

    .line-title-tag {
        font-family: 'LiSu';
        padding: 0 5px;
        color: #345262;
    }

    .demand-label {
        position: absolute;
        top: 5px;
        right: 100px;
        background: #956852;
        color: #eee;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        padding: 0 20px;
        border-radius: 3px;

    }

    .one-top {
        box-shadow: 2px 2px 1px #aaa;
        border: 1px solid #aaa;
        overflow: hidden;
        padding: 30px;
    }

    .ll-wrapper {
        margin-right: 300px;

    }

    .lr-wrapper {
        width: 250px;
        float: right;
        text-align: center;
        padding: 40px;
        border-left: 1px dotted #ddd;
        padding-left: 70px;
    }

    .tll-title {
        font-size: 24px;
        font-weight: 650;
        text-align: center;
        padding: 30px;
    }

    .tll-description {
        padding: 30px;
        text-align: left;
        font-size: 16px;
        color: #222;
        background: #f6f6f6;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .p-p {
        text-indent: 2em;
    }

    .tll-d-title {
        color: #666;
    }

    .two-top {
        margin-top: 20px;
        box-shadow: 2px 2px 1px #aaa;
        border: 1px solid #aaa;
        overflow: hidden;
        padding: 30px;
    }

    .tt-inner-wrapper {
        background: #f6f6f6;
        padding: 30px;
    }

    .prop-t {
        width: 100%;
    }

    .title-td {
        height: 30px;
        line-height: 30px;
        color: #aaa;
        width: 200px;
        font-size: 14px;
        text-align: right;
    }

    .content-td {
        font-size: 16px;
        color: #666;
        height: 30px;
        line-height: 30px;
        text-align: center;

    }

    .instr-p {
        font-size: 12px;
        color: #aaa;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }

    .demand-top {
        padding: 30px;
        font-size: 16px;
        background: #f6f6f6;
    }

    .demand-med {
        padding: 50px;
        background: #f6f6f6;
    }

    .content {
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        ;
        font-size: 14px;
        text-indent: 2em;
    }

    .api {
        padding: 10px;
        font-size: 16px;
        font-weight: 650;
        color: #666;
        text-indent: 32px;
    }

    .demand-tt {
        color: #aaa;
        font-size: 14px;
        font-weight: 800;

    }

    .right-risk-element {
        position: relative;
        padding: 20px;
    }

    .element-label {
        position: absolute;
        top: 12px;
        left: 40px;
        background: #fff;
        padding: 0 10px;
        color: #777;
    }

    .element-wrapper {
        border: 1px solid #ddd;
        padding: 20px;
        cursor: pointer;
        overflow: hidden;
    }

    .element-wrapper:hover {
        box-shadow: 2px 2px 1px #aaa;
        color: #224099;

    }

    .year-title{
        color:#555;
        font-size: 16px;
        left:30%;
        padding: 20px 0;
        height:50px;
        text-align: center;
        position: relative;
        width:fit-content;
        font-weight:600;
    }
    .descri{
        line-height: 36px;
    }
</style>